import React from "react";
import { Table, Modal, Form, Input, Button, Select, Space, Tag } from "antd";
import { useState, useEffect, useRef } from "react";
import { fetchData, putData } from "../../endpoints";
import { useMobile } from "../../hooks";
import {
  SearchOutlined,
  PlusOutlined,
  ReloadOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import AddComment from "./comment/addComment";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;

function No_Show() {
  const [form] = Form.useForm();
  const isMobile = useMobile();
  const [addnotemodal, setaddnotemodal] = useState(false);
  const [selected, setSelected] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const [selectedpatient, setselectedpatient] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [selectedappointment, setselectedappointment] = useState({});
  const [statusmodal, setstatusmodal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [SearchUserModal, setSearchUserModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [patients, setPatients] = useState([]);
  const [meMode, setMeMode] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    const savedState = sessionStorage.getItem("modalState");
    if (savedState) {
      setModalData(JSON.parse(savedState));
      setIsModalVisible(JSON.parse(savedState).showModal);
      sessionStorage.removeItem("modalState");
    }
  }, []);

  const getMeMode = async () => {
    var list_of_purchase = Cookies.get("assinees_purchase")
      .split(" ")
      .map((p) => {
        if (p) return "&purchaseId[]=" + parseInt(p);
      })
      .toString()
      .replaceAll(",", "");
    if (list_of_purchase == "") {
      setdataSource([]);
      return;
    }
    var res = await fetchData(
      `admin/purchase-step?includes[]=serviceStep&status=no_show&type=appointment&includes[]=patient${list_of_purchase}`
    );
    if (res.status == 200) {
      setdataSource(res.data.data);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <a
          href={
            "/patients/" +
            text.patient.id +
            "/services/in-progress/" +
            text.purchaseId
          }
          style={{ color: "black" }}
        >
          {text.purchaseId}
        </a>
      ) : (
        <a
          href={
            "/patients/" +
            text.patient.id +
            "/services/in-progress/" +
            text.purchaseId
          }
          style={{ color: "black" }}
        >
          {text.purchaseId}
        </a>
      ),
  });

  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    setisLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setdataSource([]);
      await getnoshow();
    } else {
      let search = "";
      if (dataIndex == "firstName") search = "fullName";
      if (dataIndex == "purchaseId") search = "purchaseId";

      var res = await fetchData(
        `admin/purchase-step?includes[]=serviceStep&status=no_show&type=appointment&includes[]=patient&${search}=` +
          selectedKeys[0]
      );
      if (res.status == 200) {
        res.data.data.map((temp) => {
          temp["name"] = temp.firstName + " " + temp.lastName;
        });

        setdataSource(res.data.data);
      }
    }
    setisLoading(false);
  };
  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdataSource([]);
    await getnoshow(1, true);
  };
  const columns = [
    {
      title: "Step",
      dataIndex: "serviceStep",
      key: "serviceStep",
      width: "25%",
      render: (u) => <p>{u.title}</p>,
    },
    {
      title: (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>Patient</div>{" "}
          <SearchOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              setSearchUserModal(true);
            }}
          />
          <ReloadOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              getnoshow(1, true);
            }}
          />
        </div>
      ),
      dataIndex: "patient",
      key: "patient",
      width: "25%",
      render: (u) => (
        <a
          href={
            privilage.indexOf(access.patientManegment.showPatient) > -1 &&
            "/patients/" + u.id
          }
          style={{ color: "black" }}
        >
          {u.firstName + " " + u.lastName}
        </a>
      ),
      // ...getColumnSearchProps("firstName"),
    },
    {
      title: "Service ID",
      dataIndex: "",
      key: "x",
      width: "25%",
      render: (u) => (
        <a
          href={
            privilage.indexOf(access.patientManegment.service.showPurchase) >
              -1 &&
            "/patients/" +
              u.patient.id +
              "/services/in-progress/" +
              u.purchaseId
          }
          style={{ color: "black" }}
        >
          {u.purchaseId}
        </a>
      ),
      ...getColumnSearchProps("purchaseId"),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) =>
        privilage.indexOf(access.todoList.addComment) > -1 && (
          <Button size={isMobile ? "small" : "default"}>
            <p
              onClick={() => {
                setSelected({
                  patientId: u.patientId,
                  purchaseId: u.purchaseId,
                  purchaseStepId: u.id,
                  firstName: u.patient.firstName,
                  lastName: u.patient.lastName,
                  patient: {
                    firstName: u.patient.firstName,
                    lastName: u.patient.lastName,
                    id: u.patientId,
                  },
                });
                setaddnotemodal(true);
              }}
              style={{
                color: "black",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              Add Comment
            </p>
          </Button>
        ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) =>
        privilage.indexOf(access.todoList.changeStatus) > -1 && (
          <Button danger size={isMobile ? "small" : "default"}>
            <p
              style={{ whiteSpace: "nowrap", cursor: "pointer" }}
              onClick={() => {
                setselectedappointment(u);
                setstatusmodal(true);
              }}
            >
              Change Status
            </p>
          </Button>
        ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) =>
        privilage.indexOf(access.todoList.editAppointment) > -1 && (
          <div
            onClick={async () => {
              setisLoading(true);
              if (u.modelIdResult) {
                var res = await fetchData(
                  `admin/appointment/${u.modelIdResult}`
                );
                if (res.status == 200) {
                  setselectedappointment(res.data.data);
                  setIsModalOpen(true);
                } else {
                  Modal.error({ title: res });
                }
              } else {
                Modal.error({ title: "Appointment not scheduled yet!" });
              }
              setisLoading(false);
            }}
            style={{ cursor: "pointer" }}
          >
            Edit
          </div>
        ),
    },
  ];

  const okPatientModal = async (id = null) => {
    setisLoading(true);
    var res;
    if (id) {
      res = await fetchData(
        `admin/purchase-step?includes[]=serviceStep&status=no_show&type=appointment&perPage=100&includes[]=patient&patientId=${id}`
      );
    } else
      res = await fetchData(
        `admin/purchase-step?includes[]=serviceStep&status=no_show&type=appointment&perPage=100&includes[]=patient&patientId=${data[selectedRowKeys].id}`
      );
    setdataSource(res.data.data);
    setSearchUserModal(false);
    setSelectedRowKeys(false);
    setisLoading(false);
  };
  const getColumnSearchPropsmodal = (dataIndex, isRemove = false) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            handleSearchmodal(selectedKeys, confirm, dataIndex);
          }}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchmodal(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetmodal(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns2 = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      onCell: (record) => {
        return {
          onClick: () => {
            okPatientModal(record.id);
          },
        };
      },
      ...getColumnSearchPropsmodal("name"),
      render: (record) => (
        <h2 style={{ cursor: "pointer" }} className="name-table">
          {record}
        </h2>
      ),
    },
    {
      title: "MRN",
      dataIndex: "id",
      key: "id",
      render: (id, record) =>
        record.verifiedAt ? (
          <span>{id}</span>
        ) : (
          <span style={{ color: "rgba(46, 108, 223, 1)", cursor: "pointer" }}>
            Not Yet Verified
          </span>
        ),
      ...getColumnSearchPropsmodal("id"),
    },
  ];

  const handleSearchmodal = async (selectedKeys, confirm, dataIndex) => {
    setisLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length == 0) {
      await getpatientmodal();
    } else {
      let search = "";
      if (dataIndex == "name") search = "fullName";
      else search = "id";
      var res = await fetchData(
        `admin/patient/?verifiedAt=true&deletedAt=false&${search}=` +
          selectedKeys[0]
      );
      if (res.status == 200) {
        setPatients(
          res.data.data.map((temp) => {
            return { ...temp, name: temp.firstName + " " + temp.lastName };
          })
        );
      }
    }
    setisLoading(false);
  };
  const handleResetmodal = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdataSource([]);
    await getpatientmodal(1, true);
  };
  const getpatientmodal = async (page = 1, reload = false) => {
    setisLoading(true);
    var temp = patients;
    if (reload) {
      temp = [];
    }
    if (patients.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        `admin/patient/?deletedAt=false&verifiedAt=true&perPage=15&page=` + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat(res.data.data)
          );
        } else {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([
                ...res.data.data,
                { createdAt: new Date().toString(), serviceTypes: [] },
              ])
          );
        }
      }
    }

    setisLoading(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const data = patients.map((p, i) => {
    return {
      ...p,
      key: i,
      name: p.firstName + " " + p.lastName,
      createdAt: new Date(p.createdAt).toDateString(),
    };
  });
  async function getnoshow(page = 1, reload = false) {
    setisLoading(true);
    if (dataSource.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        "admin/purchase-step?includes[]=serviceStep&status=no_show&type=appointment&perPage=15&includes[]=patient&page=" +
          page
      );
      var temp1 = dataSource;
      if (reload) {
        temp1 = [];
      }
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setdataSource(
            temp1
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([...res.data.data])
          );
        } else {
          setdataSource(
            temp1
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({
          title: res,
        });
      }
      //
    }
    setisLoading(false);
  }

  useEffect(() => {
    getnoshow();
    getpatientmodal();
  }, []);

  return (
    <>
      <div
        style={{
          minHeight: "150vh",
          minWidth: "100%",
          backgroundColor: "white",
        }}
      >
        <div
          style={
            isMobile
              ? {
                  padding: "0px 20px",
                  // textAlign: "center",
                  margin: "auto",
                  minHeight: "50%",
                }
              : {
                  padding: "0px 70px",
                  textAlign: "center",
                  margin: "auto",
                  minHeight: "50%",
                }
          }
        >
          <div
            style={
              isMobile
                ? {
                    padding: "40px 0px 20px 0px",
                    fontSize: "18px",
                  }
                : {
                    display: "flex",
                    justifyContent: "start",
                    padding: "30px 0px",
                    fontSize: "24px",
                  }
            }
          >
            <strong>No-show Steps</strong>
            <Button
              style={{ position: "absolute", right: "5rem" }}
              onClick={async () => {
                setisLoading(true);
                setMeMode(!meMode);
                if (!meMode) {
                  await getMeMode();
                } else {
                  await getnoshow(1, true);
                }
                setisLoading(false);
              }}
              type={meMode ? "primary" : "default"}
              loading={isLoading}
              shape="round"
            >
              Assigned to me
            </Button>
          </div>

          <div
            style={{
              fontWeight: "400",
              fontSize: "16px",
              // marginTop: "36px",
              textAlign: "start",
              marginBottom: "40px",
            }}
          >
            Review service steps that patients missed, including appointments.
          </div>
          <div style={isMobile ? { minWidth: "80vw" } : {}}>
            <Table
              scroll={{ x: "auto" }}
              loading={isLoading}
              columns={columns}
              dataSource={dataSource}
              pagination={{
                onChange: async (page) => {
                  await getnoshow(page);
                },
                pageSize: 15,
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        title=""
        open={isModalOpen}
        // onOk={handleOk}
        okText="Verify"
        onCancel={() => setIsModalOpen(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <strong
          style={{
            textAlign: "center",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            fontSize: "24px",
          }}
        >
          Edit Appointment
        </strong>
        {/* <div style={{ marginBottom: "10px" }}>
          <strong style={{marginRight:'7px'}}>ID:</strong>
          {selectedappointment.id}
        </div> */}

        <div style={{ marginBottom: "10px", marginTop: "20px" }}>
          <strong style={{ marginRight: "7px" }}>LocationTypes:</strong>
          {selectedappointment.locationType}
        </div>
        <div style={{ marginBottom: "10px" }}>
          <strong style={{ marginRight: "7px" }}>Start:</strong>
          {selectedappointment.startedAt}
        </div>
        <div style={{ marginBottom: "10px" }}>
          <strong style={{ marginRight: "7px" }}>Finish:</strong>
          {selectedappointment.finishedAt}
        </div>
        <div className="d-flex flex-row" style={{ marginBottom: "10px" }}>
          <div>
            <strong style={{ marginRight: "7px" }}>URL:</strong>
          </div>
          <a href={selectedappointment.url} style={{ color: "black" }}>
            {selectedappointment.url}
          </a>
        </div>
        <hr />
        <Form
          form={form}
          layout="vertical"
          onFinish={async (values) => {
            setisLoading(true);
            var res = await putData(
              `admin/appointment/${selectedappointment.id}`,
              values
            );
            if (res.status == 200) {
              setIsModalOpen(false);
              Modal.success({ title: "Appointments indexed successfully." });
              form.resetFields();
              getnoshow(1, true);
            }
            setisLoading(false);
          }}
        >
          <Form.Item
            name="url"
            label="New URL"
            rules={[
              {
                type: "url",
                warningOnly: true,
              },
              {
                type: "string",
                min: 3,
              },
            ]}
          >
            <Input placeholder="Enter New URL" />
          </Form.Item>
          <Form.Item label="New LocationType" name="locationType">
            <Select placeholder="Enter new LocationType">
              <Select.Option value="online">Online</Select.Option>
              <Select.Option value="in_person">In-Person</Select.Option>
              <Select.Option value="online_form">Online form</Select.Option>
              <Select.Option value="online_need_translation">
                Online with interpreter
              </Select.Option>
              <Select.Option value="offline_need_translation">
                Offline with interpreter
              </Select.Option>{" "}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
            <Button
              type="default"
              onClick={() => {
                // seteditappointmentmodalopen(false);
                // setselectedappointment({});
              }}
              style={{ marginLeft: "10px" }}
              href={selectedappointment.rescheduleUrl}
            >
              Reschedule
            </Button>
            <Button
              type="default"
              onClick={() => {
                setIsModalOpen(false);
                setselectedappointment({});
              }}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title=""
        open={statusmodal}
        okText="Verify"
        onCancel={() => setstatusmodal(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <strong
          style={{
            textAlign: "center",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            fontSize: "24px",
          }}
        >
          Change status
        </strong>

        <Form
          form={form}
          layout="vertical"
          onFinish={async (values) => {
            setisLoading(true);
            var res = await putData(
              `admin/service-purchase/${selectedappointment.purchaseId}/step/${selectedappointment.id}`,
              values
            );
            if (res.status == 200) {
              setstatusmodal(false);
              Modal.success({ title: "Status changed successfully." });
              form.resetFields();
              getnoshow(1, true);
            }
            setisLoading(false);
          }}
        >
          <Form.Item label="New Status" name="status">
            <Select placeholder="Enter new Status">
              <Select.Option value="done">Done</Select.Option>
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="is_scheduled">Is scheduled</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
            <Button
              type="default"
              onClick={() => {
                setstatusmodal(false);
                setselectedappointment({});
              }}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        style={isMobile ? {} : { maxHeight: "80vh" }}
        open={SearchUserModal}
        title="Search Patient"
        width={1000}
        onOk={() => {
          okPatientModal();
        }}
        onCancel={() => {
          setSearchUserModal(false);
          setSelectedRowKeys(false);
        }}
      >
        <div
          style={
            isMobile
              ? { minWidth: "90%" }
              : { maxHeight: "75vh", overflowY: "scroll" }
          }
        >
          <Table
            scroll={{ x: "auto" }}
            loading={isLoading}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={columns2}
            dataSource={data}
            size="small"
            style={isMobile ? { margin: "0px" } : { margin: "40px" }}
            pagination={{
              onChange: async (page) => {
                await getpatientmodal(page);
              },
              // total: 50,
              pageSize: 15,
            }}
          />
        </div>
      </Modal>
      <Modal
        title=""
        open={addnotemodal}
        top={"0"}
        width={isMobile ? "90vw" : "65vw"}
        footer={null}
        onCancel={() => setaddnotemodal(false)}
        closeIcon={null}
        destroyOnClose
      >
        <AddComment
          isOpen={addnotemodal}
          setIsOpen={setaddnotemodal}
          getComments={() => {}}
          patients={[
            {
              id: selected.patientId,
              firstName: selected.firstName,
              lastName: selected.lastName,
            },
          ]}
          purchase={null}
          defultComment={selected}
          setDefult={setSelected}
        />
      </Modal>
      <Modal
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={null}
      >
        <div className="d-flex flex-row align-items-center gap-2">
          <CloseCircleFilled style={{ color: "#ff4d4f" }} />
          <p style={{ fontWeight: "700", margin: "0" }}>
            {modalData?.message || "Something went wrong!"}
          </p>
        </div>
      </Modal>
    </>
  );
}

export default No_Show;
