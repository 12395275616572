import { React, useState } from "react";
import { Button, Form, Input, Checkbox } from "antd";
import { postWithoutToket, fetchData } from "../../endpoints";
import "./styles.scss";
import { useAssets, useMobile } from "../../hooks/index";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import access from "../../assets/access";
import full from "../../assets/fullAccess";
import { Modal } from "antd";

const SignIn = () => {
  const { getFile } = useAssets();
  const isMobile = useMobile();
  const [password, setPassword] = useState("");
  const [privilage, setPrivilage] = useState(Cookies.get("privilege") ?? full);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [remember, setRemmember] = useState(true);
  const navigator = useNavigate();
  const EmailValidate = (email) => {
    return email
      .toLowerCase()
      .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  };
  const getPreveleg = async () => {
    var res = await fetchData("user");
    if (res.status == 200) {
      localStorage.setItem("id", res.data.data.id);
      if (res.data.data.type == "user") {
        Modal.error({
          title:
            "Your account does not have permission to access this dashboard.",
        });
        return false;
      }
      if (res.data.data.role) {
        Cookies.set("privilege", res.data.data.role.privileges);
        setPrivilage(res.data.data.role.privileges);
      } else {
        Cookies.set("privilege", full);
        setPrivilage(full);
      }
      await getAssinees(res.data.data.id);
      return true;
    }
  };
  const getAssinees = async (userId) => {
    var res = await fetchData(
      `admin/user-assignment?deletedAt=false&userId=` + userId
    );
    if (res.status == 200) {
      Cookies.set(
        "assinees_patient",
        res.data.data
          .filter((a) => {
            return a.itemType == "patient";
          })
          .map((p) => {
            return p.itemId;
          })
          .toString()
          .replaceAll(",", " ")
      );

      Cookies.set(
        "assinees_purchase",
        res.data.data
          .filter((a) => {
            return a.itemType == "purchase";
          })
          .map((p) => {
            return p.itemId;
          })
          .toString()
          .replaceAll(",", " ")
      );
    } else {
      Modal.error({ title: res });
    }
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    if (!password) {
      setIsLoading(false);
      return;
    }
    if (EmailValidate(input)) {
      const body = { email: input, password: password };
      var res = await postWithoutToket("auth/login", JSON.stringify(body));
      if (res.status !== 201) {
        Swal.fire({
          title: "",
          text: res,
          icon: "error",
          confirmButtonText: "ok",
        });
      } else {
        Cookies.set("access_token", res.data.data.token);
        if (remember) {
          Cookies.set("isLogin", true, { expires: 365 });
        }
        Cookies.set("email", input);
        var is_user = await getPreveleg();
        if (is_user) {
          navigator("/toDo");
        }
      }
    } else {
      Swal.fire({
        title: "Invalid Email",
        text: "Please enter a valid email",
        icon: "error",
        confirmButtonText: "ok",
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="background">
      {!isMobile && (
        <img
          src={getFile("LightLogo")}
          alt="Logo"
          style={{
            position: "absolute",
            top: "35px",
            left: "35px",
            maxWidth: "20vw",
          }}
        />
      )}
      {isMobile && (
        <img
          src={getFile("Logo")}
          alt="Logo"
          style={{
            position: "absolute",
            top: "35px",
            left: "50%",
            transform:"translate(-50%,0)",
            maxWidth: "50vw",
            zIndex: "3",
          }}
        />
      )}
      <div className="signIn-box">
        <h1>Sign In</h1>
        <Form
          // size="large"
          name="signIn"
          layout={"vertical"}
          style={{ maxWidth: 900, width: "80%" }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please enter your email!" }]}
          >
            <Input
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Item>

          <div className="d-flex flex-row w-100 align-items-start">
            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{ span: 16 }}
              className="col-8"
            >
              <Checkbox
                value={remember}
                onChange={(e) => {
                  setRemmember(e.target.value);
                }}
              >
                Remember me
              </Checkbox>
            </Form.Item>
            <span
              style={{
                textAlign: "right",
                color: "#4C4C4C",
              }}
              className="col-4 mt-1"
            >
              {" "}
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator("/forgot-password");
                }}
              >
                Forgot Password
              </span>
            </span>
          </div>

          <Form.Item wrapperCol={{ span: 4, offset: !isMobile ? 10 : 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={isLoading}
              onClick={handleSubmit}
              style={{ marginLeft: "40%" }}
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default SignIn;
