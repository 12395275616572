import { React, useState, useRef, useEffect } from "react";
import "./styles.scss";
import {
  SearchOutlined,
  ExclamationCircleFilled,
  DownOutlined,
  UpOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  PlusOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { Button, Input, Space, Table, Tag } from "antd";
import Highlighter from "react-highlight-words";
import { deleteData, fetchData, postData, putData } from "../../endpoints";
import PatientPage from "../patientPage";
import { Modal } from "antd";
import { useMobile } from "../../hooks";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import access from "../../assets/access";

import AddComment from "../ToDo/comment/addComment";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const PatientManagement = () => {
  const isMobile = useMobile();
  const { patientId } = useParams();
  const UserId = localStorage.getItem("id");
  const path = useLocation().pathname;
  const navigator = useNavigate();
  const [patients, setPatients] = useState([]);
  const [removedPatients, setRemovedPatients] = useState([]);
  const [patient, setPatient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [patientLoading, setPatientLoading] = useState(true);
  const [showRemove, setShowRemove] = useState(false);
  const [serviceTypesarray, setServiceTypes] = useState([]);
  const [domain, setDomain] = useState("");
  const [selected, setSelected] = useState({});
  const [addnotemodal, setaddnotemodal] = useState(false);
  const [isOpenAssign, setisOpenAssign] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [adminsOnPatient, setAdminsOnPatient] = useState([]);
  const [internalLoadnig, setInternalLoading] = useState(false);
  const [meMode, setMeMode] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    const savedState = sessionStorage.getItem("modalState");
    if (savedState) {
      setModalData(JSON.parse(savedState));
      setIsModalVisible(JSON.parse(savedState).showModal);
      sessionStorage.removeItem("modalState");
    }
  }, []);
  const getAssinees = async (userId) => {
    var res = await fetchData(
      `admin/user-assignment?deletedAt=false&userId=` + userId
    );
    if (res.status == 200) {
      Cookies.set(
        "assinees_patient",
        res.data.data
          .filter((a) => {
            return a.itemType == "patient";
          })
          .map((p) => {
            return p.itemId;
          })
          .toString()
          .replaceAll(",", " ")
      );
      Cookies.set(
        "assinees_purchase",
        res.data.data
          .filter((a) => {
            return a.itemType == "purchase";
          })
          .map((p) => {
            return p.itemId;
          })
          .toString()
          .replaceAll(",", " ")
      );
    } else {
      Modal.error({ title: res });
    }
  };
  const getMeMode = async () => {
    var list_of_patient = Cookies.get("assinees_patient")
      .split(" ")
      .map((p) => {
        if (p) return "&id[]=" + parseInt(p);
      })
      .toString()
      .replaceAll(",", "");
    if (list_of_patient == "") {
      setPatients([]);
      return;
    }
    var res = await fetchData(
      `admin/patient/?deletedAt=false&perPage=100${list_of_patient}`
    );
    if (res.status == 200) {
      setPatients(res.data.data);
    }
  };
  const getAdminsOnPatient = async (patientId) => {
    setInternalLoading(true);
    var res = await fetchData(
      `admin/user-assignment?itemType=patient&deletedAt=false&includes[]=user&itemId=` +
        patientId
    );
    if (res.status == 200) {
      setAdminsOnPatient(
        res.data.data.map((item) => {
          if (item.user)
            return {
              ...item,
              name: item.user.firstName + " " + item.user.lastName,
              email: item.user.email,
              type: item.user.type,
            };
          else {
            return item;
          }
        })
      );
    }
    setInternalLoading(false);
  };

  const getAdmins = async (page = 1, reload = false) => {
    var temp = admins;
    if (reload) {
      temp = [];
    }
    setLoading(true);
    if (admins.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        "admin/user/?disabledAt=false&deletedAt=false&perPage=15&type[]=admin&type[]=super_user&page=" +
          page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setAdmins(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat(res.data.data)
          );
        } else {
          setAdmins(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([...res.data.data, {}])
          );
          console.log(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }
    setLoading(false);
  };
  const showRemoveConfirm = async (a, selected) => {
    confirm({
      title: "Are you sure you want to unassign this assignment?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeAssign(a, selected);
      },
      onCancel() {},
    });
  };
  const removeAssign = async (a, selected) => {
    setInternalLoading(true);
    var res = await deleteData("admin/user-assignment/" + a.id);
    if (res.status == 200) {
      await getAdminsOnPatient(selected.id);
      await getAssinees(UserId);
      Modal.success({ title: res.data.message });
    } else {
      Modal.error({ title: res });
    }
    setInternalLoading(false);
  };

  const getColumnSearchPropsadmin = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchadmin(selectedKeys, confirm, dataIndex)
          }
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchadmin(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetadmin(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleSearchadmin = async (selectedKeys, confirm, dataIndex) => {
    setLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setAdmins([]);
      await getAdmins();
    } else {
      let search = "";
      if (dataIndex == "type") search = "type";
      if (dataIndex == "email") search = "email";
      if (dataIndex == "name") search = "fullName";

      var res = await fetchData(
        `admin/user/?disabledAt=false&deletedAt=false&perPage=4000&type[]=super_user&type[]=admin&${search}=` +
          selectedKeys[0]
      );

      if (res.status == 200) {
        setAdmins(res.data.data);
      }
    }
    setLoading(false);
  };

  const handleResetadmin = (clearFilters) => {
    clearFilters();
    setSearchText("");
    getAdmins(1, true);
  };

  const getPatient = async (page = 1, reload = false) => {
    var temp = patients;
    if (reload) {
      temp = [];
    }
    setPatientLoading(true);
    if (patients.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        `admin/patient/?deletedAt=false&perPage=15&page=` + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat(res.data.data)
          );
        } else {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([
                ...res.data.data,
                { createdAt: new Date().toString(), serviceTypes: [] },
              ])
          );
        }
      }
    }

    setPatientLoading(false);
  };
  const getConfig = async () => {
    var res2 = await fetchData("user/config");
    if (res2.status == 200) {
      setDomain(res2.data.data.storage.bucket.url + "/");
    }
  };
  const getRemovedPatient = async (page = 1) => {
    setLoading(true);
    if (removedPatients.length < (page - 1) * 15 + 2) {
      var res2 = await fetchData(
        `admin/patient/?deletedAt=true&perPage=15&page=` + page
      );
      if (res2.status == 200) {
        if (res2.data.data.length < 15) {
          setRemovedPatients(
            removedPatients
              .filter((a, i) => {
                return a.firstName;
              })
              .concat(res2.data.data)
          );
        } else {
          setRemovedPatients(
            removedPatients
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([
                ...res2.data.data,
                { deletedAt: new Date().toString(), serviceTypes: [] },
              ])
          );
        }
      }
    }
    setLoading(false);
  };
  const getServiceTypes = async () => {
    var res = await fetchData("admin/service-type");
    if (res.status == 200) {
      var temp = [];
      res.data.data.map((s) => {
        temp = [...temp, s];
        if (s.children) {
          temp = temp.concat(s.children);
        }
      });
      setServiceTypes(temp);
    }
  };
  const getOnePatient = async (id) => {
    setLoading(true);
    var res = await fetchData(`admin/patient/${id}`);
    if (res.status == 200) {
      setPatient({
        ...res.data.data,
        name: res.data.data.firstName + " " + res.data.data.lastName,
      });
      setIsOpen(true);
    } else {
      sessionStorage.setItem(
        "modalState",
        JSON.stringify({
          showModal: true,
          message: "The patient was not found!",
        })
      );
      navigator(-1);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (
      patient == null &&
      patientId &&
      privilage.indexOf(access.patientManegment.showPatient) > -1
    ) {
      getOnePatient(patientId);
      getConfig();
      return;
    }
    getServiceTypes();
    getRemovedPatient();
    getPatient();
    getConfig();
    getAdmins();
  }, []);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysadmin, setSelectedRowKeysadmin] = useState([]);

  const searchInput = useRef(null);
  const { confirm } = Modal;

  const showDeleteConfirm = async (arr) => {
    confirm({
      title: "Are you sure you want to disable this patient?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeGrupe(arr);
      },
      onCancel() {},
    });
  };

  const removePatient = async (id) => {
    setLoading(true);
    var res = await deleteData(`admin/patient/${id}`);
    if (res.status === 200) {
      setLoading(false);
      return true;
    } else {
      setLoading(false);
      return false;
    }
  };
  const removeGrupe = async (listOfPatients) => {
    let isOk = true;
    for (var i in listOfPatients) {
      var e = listOfPatients[i];
      var res = await removePatient(e.id);
      if (!res) {
        isOk = false;
      }
    }
    if (isOk) {
      await getPatient(1, true);
      await getRemovedPatient(1, true);
      setSelectedRowKeys([]);
      Modal.success({ title: "Patient disabled successfully." });
    } else {
      Modal.error({ title: "There was a problem, please try again." });
    }
  };
  const verifyPatient = async (id) => {
    var res = await putData(`admin/patient/${id}/verify`);
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  const verifyGrupe = async (listOfPatients) => {
    setLoading(true);
    let isOk = true;
    for (var e in listOfPatients) {
      var res = await verifyPatient(listOfPatients[e].id);
      if (!res) {
        isOk = false;
      }
    }

    if (isOk) {
      await getPatient(1, true);
      setSelectedRowKeys([]);
      setLoading(false);
      Modal.success({ title: "Patient has been successfully verified." });
    } else {
      setLoading(false);
      Modal.error({ title: "There was a problem, please try again." });
    }
  };
  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    // confirm();
    setLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length == 0) {
      await getPatient();
    } else {
      let search = "";
      if (dataIndex == "name") search = "fullName";
      else search = "id";
      var res = await fetchData(`admin/patient/?${search}=` + selectedKeys[0]);
      if (res.status == 200) {
        setPatients(
          res.data.data.filter((u) => {
            return !u.deletedAt;
          })
        );
      }
    }
    setLoading(false);
  };
  const handleRemoveSearch = async (selectedKeys, confirm, dataIndex) => {
    // confirm();
    setLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      await getPatient();
    } else {
      let search = "";
      if (dataIndex == "name") search = "fullName";
      else search = "id";
      var res = await fetchData(`admin/patient/?${search}=` + selectedKeys[0]);
      if (res.status == 200) {
        setRemovedPatients(
          res.data.data.filter((u) => {
            return u.deletedAt;
          })
        );
      }
    }
    setLoading(false);
  };
  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    await getPatient(1, true);
  };
  const getColumnSearchProps = (dataIndex, isRemove = false) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            isRemove
              ? handleRemoveSearch(selectedKeys, confirm, dataIndex)
              : handleSearch(selectedKeys, confirm, dataIndex);
          }}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              isRemove
                ? handleRemoveSearch(selectedKeys, confirm, dataIndex)
                : handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) == index);
  }

  const columns2 = [
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      width: "20%",
      onCell: (record) => {
        return {
          onClick: () => {
            // setPatient(record);
            // navigator(path + "/" + record.id, {
            //   state: { previousPath: path },
            // });
            // setIsOpen(true);
          },
        };
      },
      ...getColumnSearchProps("name", true),
      render: (record) =>
        privilage.indexOf(access.patientManegment.showPatient) > -1 ? (
          <a
            href={path + "/" + record.id}
            style={{ cursor: "pointer", color: "black" }}
            className="name-table"
          >
            {record.name}
          </a>
        ) : (
          <p className="name-table">{record.name}</p>
        ),
    },
    {
      title: "MRN",
      dataIndex: "id",
      key: "id",
      render: (id, record) =>
        record.verifiedAt ? (
          <span>{id}</span>
        ) : (
          privilage.indexOf(access.patientManegment.verifyPatient) > -1 && (
            <span
              onClick={async () => {
                await verifyGrupe([record]);
              }}
              style={{ color: "rgba(46, 108, 223, 1)", cursor: "pointer" }}
            >
              Verify
            </span>
          )
        ),
      width: "15%",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Service",
      dataIndex: "serviceTypes",
      key: "serviceTypes",
      width: "25%",
      responsive: ["md"],
      render: (serviceTypes, record) => (
        <div className="d-flex flex-column gap-2">
          {serviceTypes &&
            serviceTypes.map((s, i) => {
              let color = ["geekblue", "purple", "magenta"];
              return (
                <Tag color={color[i % 3]} key={s}>
                  {s.serviceType.title}
                </Tag>
              );
            })}
        </div>
      ),
    },
    {
      title: "Date Disabled",
      dataIndex: "deletedAt",
      key: "deletedAt",
      responsive: ["md"],
      ...getColumnSearchProps("deletedAt"),
      sorter: (a, b) => new Date(a.deletedAt) - new Date(b.deletedAt),
      sortDirections: ["descend", "ascend"],
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      width: "25%",
      ...getColumnSearchProps("name"),
      render: (record) =>
        privilage.indexOf(access.patientManegment.showPatient) > -1 ? (
          <a
            href={path + "/" + record.id}
            style={{ cursor: "pointer", color: "black" }}
            className="name-table"
          >
            {record.name}
          </a>
        ) : (
          <p className="name-table">{record.name}</p>
        ),
    },
    {
      title: "MRN",
      dataIndex: "id",
      key: "id",
      ...getColumnSearchProps("id"),
      render: (id, record) =>
        record.verifiedAt ? (
          <span>{id}</span>
        ) : (
          privilage.indexOf(access.patientManegment.verifyPatient) > -1 && (
            <span
              onClick={async () => {
                await verifyGrupe([record]);
              }}
              style={{ color: "rgba(46, 108, 223, 1)", cursor: "pointer" }}
            >
              Verify
            </span>
          )
        ),
      width: "15%",
    },
    {
      title: "Service",
      dataIndex: "serviceTypes",
      key: "serviceTypes",
      width: "25%",
      responsive: ["md"],
      render: (serviceTypes, record) => (
        <div className="d-flex flex-column gap-2">
          {serviceTypes &&
            serviceTypes.map((s, i) => {
              let color = ["geekblue", "purple", "magenta"];

              return (
                <Tag color={color[i % 3]} key={s}>
                  {s.serviceType.title}
                </Tag>
              );
            })}
        </div>
      ),
      // filters: serviceTypesarray
      //   .filter((t) => {
      //     return t.children.length > 0;
      //   })
      //   .map((t) => {
      //     return {
      //       key: t.id,
      //       text: t.title,
      //       children: t.children.map((s) => {
      //         return { text: s.title, value: s.id, key: s.id };
      //       }),
      //     };
      //   }),
      // // onFilter: (value, record) => isTher(value, record),
      // filterMode: "tree",
      // filterIcon: <FilterOutlined />,
    },

    Table.EXPAND_COLUMN,
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) =>
        privilage.indexOf(access.todoList.addComment) > -1 && (
          <div
            style={{
              // marginRight: "15px",
              alignItems: "center",
              justifyContent: "center",
            }}
            className=" d-flex flex-row "
          >
            <a
              onClick={() => {
                setSelected({
                  patientId: u.id,
                  firstName: u.firstName,
                  lastName: u.lastName,
                });
                setaddnotemodal(true);
              }}
              style={{ color: "black" }}
            >
              Add comment
            </a>
          </div>
        ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) =>
        privilage.indexOf(access.patientManegment.disablePatient) > -1 && (
          <div
            style={{
              marginRight: "15px",
              alignItems: "center",
              justifyContent: "center",
            }}
            className=" d-flex flex-row gap-4"
          >
            <a
              onClick={async (e) => {
                e.stopPropagation();
                await showDeleteConfirm([u]);
              }}
              style={{ color: "#BC2323" }}
            >
              Disable
            </a>
          </div>
        ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const rowSelectionadmin = {
    selectedRowKeysadmin,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeysadmin(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const data = patients.map((p, i) => {
    return {
      ...p,
      key: i,
      name: p.firstName + " " + p.lastName,
      createdAt: new Date(p.createdAt).toDateString(),
    };
  });
  const removedData = removedPatients.map((p, i) => {
    return {
      ...p,
      key: i,
      name: p.firstName + " " + p.lastName,
      deletedAt: new Date(p.deletedAt).toDateString(),
    };
  });

  const columns_admin = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // width: "20%",
      ...getColumnSearchPropsadmin("name"),
      render: (u) => <div>{u ? u : "name not provide"}</div>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      // width: "30%",
      ...getColumnSearchPropsadmin("email"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      // width: "20%",
      ...getColumnSearchPropsadmin("type"),
    },
  ];

  if (isOpen) {
    return (
      <>
        <PatientPage
          patient={patient}
          setPatient={setPatient}
          setIsOpen={setIsOpen}
          loading={loading}
          setLoading={setLoading}
          isMobile={isMobile}
          serviceTypes={serviceTypesarray}
          getPatients={getPatient}
          getServiceTypes={getServiceTypes}
          domain={domain}
        />
        <Modal
          visible={isModalVisible}
          onOk={() => {
            setIsModalVisible(false);
          }}
          cancelButtonProps={{ style: { display: "none" } }}
          closeIcon={null}
        >
          <div className="d-flex flex-row align-items-center gap-2">
            <CloseCircleFilled style={{ color: "#ff4d4f" }} />
            <p style={{ fontWeight: "700", margin: "0" }}>
              {modalData?.message || "Something went wrong!"}
            </p>
          </div>
        </Modal>
      </>
    );
  } else {
    return (
      <div
        style={{ backgroundColor: "white", minHeight: "100vh" }}
        className={
          isMobile
            ? "d-flex flex-column w-100"
            : "d-flex flex-column w-100 py-5 px-5"
        }
        dir="ltr"
      >
        <div className={`${isMobile ? "col-11 mx-3" : "col-11 mx-3"}`}>
          <div className="table-box w-100 py-4 align-items-center">
            <div className="d-flex flex-row align-items-center px-4 py-4 w-100">
              <span
                style={
                  isMobile
                    ? { fontSize: "18px", fontWeight: "700", width: "20%" }
                    : { fontSize: "24px", fontWeight: "700", width: "20%" }
                }
              >
                Patients list
              </span>
              <Button
                style={{ position: "absolute", right: "5rem" }}
                onClick={async () => {
                  setLoading(true);
                  setMeMode(!meMode);
                  if (!meMode) {
                    await getMeMode();
                  } else {
                    await getPatient(1, true);
                  }
                  setLoading(false);
                }}
                type={meMode ? "primary" : "default"}
                loading={loading}
                shape="round"
              >
                Assigned to me
              </Button>
              <div
                className="d-flex flex-row gap-3"
                style={{
                  justifyContent: "flex-end",
                  width: "85%",
                  fontWeight: "bold",
                }}
              >
                {selectedRowKeys.length > 0 && (
                  <>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedRowKeys([]);
                      }}
                    >
                      Cancel
                    </span>
                    {privilage.indexOf(access.patientManegment.verifyPatient) >
                      -1 && (
                      <span
                        onClick={async () => {
                          verifyGrupe(
                            data.filter((u) => {
                              let is;
                              for (let i = 0; i < selectedRowKeys.length; i++) {
                                if (u.key === selectedRowKeys[i]) {
                                  is = true;
                                  break;
                                } else {
                                  is = false;
                                }
                              }
                              return is;
                            })
                          );
                        }}
                        style={{
                          color: "rgba(46, 108, 223, 1)",
                          cursor: "pointer",
                        }}
                      >
                        Verify
                      </span>
                    )}
                    {privilage.indexOf(access.patientManegment.disablePatient) >
                      -1 && (
                      <span
                        onClick={async () => {
                          await showDeleteConfirm(
                            data.filter((u) => {
                              let is;
                              for (let i = 0; i < selectedRowKeys.length; i++) {
                                if (u.key === selectedRowKeys[i]) {
                                  is = true;
                                  break;
                                } else {
                                  is = false;
                                }
                              }
                              return is;
                            })
                          );
                        }}
                        style={{ color: "#BC2323", cursor: "pointer" }}
                      >
                        Disable
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
            <Table
              scroll={{ x: "auto" }}
              loading={loading || patientLoading}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              columns={columns}
              expandIcon={({ expanded, onExpand, record }) => {
                return expanded ? (
                  <div
                    onClick={(e) => onExpand(record, e)}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Assignees
                    <CaretUpOutlined
                      // onClick={(e) => onExpand(record, e)}
                      style={
                        isMobile ? { fontSize: "8px" } : { fontSize: "18px" }
                      }
                    />
                  </div>
                ) : (
                  <div
                    onClick={(e) => {
                      getAdminsOnPatient(record.id);
                      onExpand(record, e);
                    }}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Assignees
                    <CaretDownOutlined
                      style={
                        isMobile ? { fontSize: "8px" } : { fontSize: "18px" }
                      }
                      onClick={(e) => {
                        getAdminsOnPatient(record.id);
                        onExpand(record, e);
                      }}
                    />
                  </div>
                );
              }}
              expandable={{
                expandedRowRender: (record) => (
                  <Table
                    dataSource={adminsOnPatient}
                    columns={columns_admin
                      .filter((c) => {
                        return c.title != "Type";
                      })
                      .concat({
                        title: "",
                        key: "x",
                        render: (a) => (
                          <Button
                            type="text"
                            danger
                            onClick={async () => {
                              await setSelected(record);
                              showRemoveConfirm(a, record);
                            }}
                          >
                            Unassign
                          </Button>
                        ),
                      })}
                    loading={internalLoadnig}
                    footer={() => (
                      <div className="d-flex flex-row gap-1 px-4">
                        <PlusOutlined
                          style={{
                            color: "rgba(46, 108, 223, 1)",
                          }}
                        />
                        <p
                          style={{
                            margin: "0",
                            color: "#2E6CDF",
                            fontSize: "12px",
                            fontWeight: "700",
                            cursor: "pointer",
                            whiteSpace: "nowrap",
                          }}
                          onClick={async () => {
                            setisOpenAssign(true);
                            if (admins.length == 0) {
                              await getAdmins();
                            }
                            setSelected({ patientId: record.id });
                          }}
                        >
                          Add Assignees
                        </p>
                      </div>
                    )}
                  />
                ),
              }}
              // onChange={filterServiceType}
              dataSource={data}
              size="small"
              style={{ width: "100%" }}
              pagination={{
                onChange: async (page) => {
                  await getPatient(page);
                },
                pageSize: 15,
              }}
            />
          </div>
          <div className="table-box w-100 py-4 mx-3 align-items-center">
            <div
              style={
                isMobile
                  ? {
                      width: "100%",
                      textAlign: "left",
                      fontSize: "18px",
                      fontWeight: "700",
                      marginBottom: "1.5rem",
                    }
                  : {
                      width: "100%",
                      textAlign: "left",
                      fontSize: "24px",
                      fontWeight: "700",
                      marginBottom: "1.5rem",
                    }
              }
            >
              <span style={{ marginRight: "15px" }}>Disabled Patients</span>
              {!showRemove ? (
                <DownOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setShowRemove(true);
                  }}
                />
              ) : (
                <UpOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setShowRemove(false);
                  }}
                />
              )}
            </div>
            {showRemove && (
              <Table
                scroll={{ x: "auto" }}
                loading={loading}
                pagination={{
                  onChange: async (page) => {
                    await getRemovedPatient(page);
                  },
                  pageSize: 15,
                }}
                columns={columns2}
                dataSource={removedData}
                size="small"
                style={{ width: "100%" }}
              />
            )}
          </div>
        </div>
        <Modal
          title=""
          open={addnotemodal}
          top={"0"}
          width={isMobile ? "90vw" : "65vw"}
          footer={null}
          onCancel={() => setaddnotemodal(false)}
          closeIcon={null}
          destroyOnClose
        >
          <AddComment
            isOpen={addnotemodal}
            setIsOpen={setaddnotemodal}
            getComments={() => {}}
            patients={[
              {
                id: selected.patientId,
                firstName: selected.firstName,
                lastName: selected.lastName,
              },
            ]}
            purchase={null}
            defultComment={{ ...selected, patient: { ...selected } }}
            setDefult={setSelected}
          />
        </Modal>
        <Modal
          title=""
          open={isOpenAssign}
          style={{ top: "5vh" }}
          width={isMobile ? "90vw" : "65vw"}
          footer={
            <div style={{ textAlign: "center" }}>
              <Button
                type="primary"
                size="large"
                onClick={async () => {
                  setLoading(true);
                  var success = true;
                  for (var row in selectedRowKeysadmin) {
                    var res = await postData("admin/user-assignment", {
                      userId: selectedRowKeysadmin[row],
                      itemType: "patient",
                      itemId: selected.patientId,
                    });
                    if (res.status != 201) {
                      success = false;
                      Modal.error({ title: res });
                    }
                  }
                  if (success) {
                    Modal.success({ title: res.data.message });
                  }
                  setLoading(false);
                  getAdminsOnPatient(selected.patientId);
                  getAssinees(UserId);
                  setSelected({});
                  setisOpenAssign(false);
                }}
              >
                Assign
              </Button>
            </div>
          }
          onCancel={() => setisOpenAssign(false)}
          closeIcon={null}
          destroyOnClose
        >
          <div>
            <div style={{ textAlign: "end" }}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setisOpenAssign(false)}
                style={{ cursor: "pointer" }}
              >
                <path
                  d="M9.71012 8.00014L15.6459 2.06441C16.118 1.59228 16.118 0.826413 15.6459 0.354269C15.1738 -0.11809 14.4079 -0.11809 13.9358 0.354269L7.99997 6.29025L2.06442 0.354507C1.59229 -0.117852 0.826181 -0.117852 0.354272 0.354507C-0.118091 0.826633 -0.118091 1.5925 0.354272 2.06465L6.28982 8.00014L0.354272 13.9359C-0.118091 14.408 -0.118091 15.1739 0.354272 15.646C0.590333 15.8821 0.899664 16 1.2092 16C1.51852 16 1.82811 15.8821 2.06413 15.646L7.99991 9.71052L13.9357 15.646C14.1718 15.8821 14.4815 16 14.7906 16C15.0997 16 15.4095 15.8821 15.6455 15.646C16.1177 15.1739 16.1177 14.408 15.6455 13.9359L9.71012 8.00014Z"
                  fill="#4C4C4C"
                />
              </svg>
            </div>

            <div
              style={{
                textAlign: "center",
                margin: "auto ",
                width: "95%",
                marginTop: "-30px",
              }}
            >
              <div style={{ fontSize: "24px", fontWeight: "700" }}>
                Add Assignees
              </div>
              <hr />
            </div>
            <Table
              scroll={{ x: "auto" }}
              loading={loading}
              rowSelection={{
                type: "radio",
                ...rowSelectionadmin,
              }}
              columns={columns_admin}
              size="small"
              style={{ width: "100%" }}
              dataSource={admins.map((a) => {
                return {
                  ...a,
                  key: a.id,
                  name: a.firstName + " " + a.lastName,
                };
              })}
              pagination={{
                onChange: async (page) => {
                  await getAdmins(page);
                },
                pageSize: 15,
              }}
            />
          </div>
        </Modal>
        <Modal
          visible={isModalVisible}
          onOk={() => {
            setIsModalVisible(false);
          }}
          cancelButtonProps={{ style: { display: "none" } }}
          closeIcon={null}
        >
          <p style={{ fontWeight: "700" }}>
            {modalData?.message || "Something went wrong!"}
          </p>
        </Modal>
      </div>
    );
  }
};

export default PatientManagement;
